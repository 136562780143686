<script setup lang="ts"></script>

<template>
	<main class="layout--homepage">
		<!-- homepage layout -->
		<NavTop :logo="true" />
		<Nav></Nav>
		<slot />
		<Footer />
	</main>
</template>

<style scoped></style>
